'use client'

import React, { useCallback, useImperativeHandle, useRef } from 'react'

import { CSS, styled } from '../stitches.config'

export interface IconProps {
  /**
   * Icon name, It need to use the matieral ui icon name
   */
  name?: string

  /**
   * set CSS
   */
  css?: CSS

  /**
   * set material ui icon type, It can set the material ui icon type
   */
  type?: 'filled' | 'outlined'

  /**
   * Icon size
   */
  size?: number

  /**
   * onClick
   */
  onClick?: (e) => void

  pointer?: boolean

  className?: string

  disabled?: boolean
}

// export const Icon: React.FC<IconProps> = (props: IconProps) => {
export const Icon = React.forwardRef<HTMLSpanElement, IconProps>((props: IconProps, forwardRef) => {
  const {
    type = 'filled',
    name = 'add',
    size = 24,
    css,
    onClick,
    className = '',
    pointer = false,
    disabled = false,
  } = props

  // Ref for the button
  const iconRef = useRef<HTMLSpanElement>(null)
  useImperativeHandle(forwardRef, () => iconRef.current as HTMLSpanElement)

  let classNameForType = 'material-icons'

  switch (type) {
    case 'filled':
      classNameForType = 'material-icons'
      break
    case 'outlined':
      classNameForType = 'material-icons-outlined'
      break
  }

  const cursor = disabled ? 'not-allowed' : typeof onClick === 'undefined' && !pointer ? 'default' : 'pointer'

  const handleClick = useCallback(
    e => {
      if (!disabled && onClick) onClick(e)
    },
    [onClick, disabled],
  )

  return (
    <StyledIcon
      ref={iconRef}
      disabled={disabled}
      css={{ cursor, ...css }}
      style={{ fontSize: `${size}px` }}
      className={`${classNameForType} ${className}`}
      onClick={handleClick}>
      {name}
    </StyledIcon>
  )
})

Icon.displayName = 'Icon'

const StyledIcon = styled('span', {
  userSelect: 'none',
  color: 'inherit',
  variants: {
    disabled: {
      true: {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
      false: {
        opacity: 1,
      },
    },
  },
  defaultVariants: {
    disabled: false,
  },
})
