import { Flex } from '@pattern-library/src/basicUI/Flex'
import { Button } from 'pattern-library/src/basicUI/Button'
import { Text } from 'pattern-library/src/basicUI/Text'
import { styled } from 'pattern-library/src/stitches.config'
import { FC } from 'react'

const Container = styled('div', {
  margin: '21px 0',
})

export const FormLayout: FC<{
  autocomplete?: 'off' | 'on'
  buttonLabel?: string
  handleSubmit: (formData: any) => any
  errorMessage?: string
  formClassName?: string
  submitting?: boolean
  children: React.ReactNode
  disabled?: boolean
}> = ({
  buttonLabel = '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleSubmit,
  errorMessage = '',
  formClassName = '',
  submitting = false,
  children,
  disabled = false,
}) => {
  return (
    <Container className={formClassName}>
      <form onSubmit={handleSubmit}>
        <Flex gap={3} direction={'column'}>
          {children}
        </Flex>
        <Button
          css={{ marginTop: '$5' }}
          data-intercom-target={buttonLabel}
          onClick={handleSubmit}
          variant="secondary"
          label={buttonLabel}
          buttonState={submitting ? 'waiting' : 'default'}
          fullWidth={true}
          disabled={disabled}
          buttonType="submit"
        />
      </form>
      {errorMessage && (
        <div>
          <Text css={{ backgroundColor: '$ale', color: '$gs1', marginTop: '$3', display: 'inline-block', px: '$2' }}>
            {errorMessage}
          </Text>
        </div>
      )}
    </Container>
  )
}
