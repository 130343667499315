import { ForwardedRef, MutableRefObject } from 'react'

/**
 *
 * Debouce function. This prevents your UI code from needing to process every event and also drastically reduces the number of API calls sent to your server.
 * documentaiton: https://stackoverflow.com/questions/5999998/how-can-i-check-if-a-javascript-variable-is-function-type
 *
 * @param  {void} func: the function to delay execution.
 * @param  {Number} delay: in ms, the delay between calls. Requested calls between the delay will be cancelled.
 */

export const debounce = (func: Function, delay: number) => {
  let inDebounce: NodeJS.Timeout | undefined = undefined

  return (...args: Object[]) => {
    const context = this

    if (inDebounce) {
      clearTimeout(inDebounce)
    }

    return (inDebounce = setTimeout(function () {
      return func.apply(context, args)
    }, delay))
  }
}

export const mergeRefs = (...refs: (ForwardedRef<HTMLDivElement> | MutableRefObject<HTMLButtonElement | null>)[]) => {
  // https://www.davedrinks.coffee/how-do-i-use-two-react-refs/
  const filteredRefs = refs.filter(Boolean)
  if (!filteredRefs.length) return null

  return (inst: HTMLDivElement | null) => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst)
      } else if (ref) {
        ref.current = inst
      }
    }
  }
}

export const SAVED_FILTERS_LOCAL_STORAGE_KEY = 'saved_filters'
export const SAVED_CUSTOM_FILTERS_LOCAL_STORAGE_KEY = 'saved_custom_filters'
