export const unauthenticatedPaths = {
  login: '/login',
  register: '/signup',
  resetPassword: '/reset-password',
  resetPasswordSubmit: '/reset-password-submit',
  newPasswordRequired: '/new-password-required',
  confirmUser: '/confirm-user',
} as const

export const authenticatedPaths = {
  profileSettings: '/profile-settings',
  agencyId: '/:agencyId',
  account: '/account',
  billing: '/billing',
  support: '/support',
  brandPath: '/:brandPath',
  workspaceSettings: '/workspace-settings',
  workspaceFeeds: '/workspace-feeds',
  workspaceFeedsNew: '/workspace-feeds/new-feed',
  workspaceHome: '/',
  workspaceAiRules: '/workspace-ai-rules',
  newPublication: '/new-publication',
  newSystem: '/new-system',
  newWorkspace: '/new-workspace',
  siteHome: '/',
  siteCollections: '/collections',
  siteCollectionsNew: '/collections/new',
  siteAssets: '/assets',
  siteAnalytics: '/analytics',
  siteArticles: '/stories',
  siteWriters: '/writers',
  siteSections: '/sections',
  siteTags: '/tags',
  siteFrontPage: '/front-page',
  siteNewsletters: '/newsletters',
  siteTermsAndConditions: '/terms-and-conditions',
  sitePrivacyPolicy: '/privacy-policy',
  siteFAQ: '/faq',
  siteAccountPageEdit: '/account-page',
  siteGift: '/gift',
  siteContactUs: '/contact-us',
  siteSubscribe: '/subscribe',
  siteMenus: '/menus',
  siteTemplate: '/template',
  siteSettingsGeneral: '/settings/general',
  siteSettingsAP: '/settings/associated-press',
  siteSettingsFeeds: '/settings/feeds',
  siteSettingsChatbotPublication: '/settings/chatbot-publication',
  siteSettingsChatbotCMS: '/settings/chatbot-cms',
  siteSettingsWeather: '/settings/weather-api',
  siteSettingsPricing: '/settings/subscription-plans',
  siteSettingsPricingConnectStripeAccountRefresh: '/settings/subscription-plans/connect-stripe-account-refresh',
  siteSettingsPricingNew: '/settings/subscription-plans/new',
  siteSettingsGiftNew: '/settings/subscription-plans/gifts/new',
  siteSettingsGiftEdit: '/settings/subscription-plans/gifts/',
  siteSettingsPromotionNew: '/settings/subscription-plans/promotions/new',
  siteSettingsPromotionEdit: '/settings/subscription-plans/promotions/',
  siteSettingsArticleGeneration: '/settings/story-generation',
  siteSettingsUsers: '/settings/users',
  siteSettingsUserNew: '/settings/users/new',
  siteSettingsUserEdit: '/settings/users/:userId',
  siteSettingsGoogleAnalytics: '/settings/google-analytics',
  siteSettingsAdvertisement: '/settings/advertisement',
  siteSettingsFotomoto: '/settings/fotomoto',
  siteSettingsNewsletters: '/settings/newsletters',
  siteSettingsNewslettersNew: '/settings/newsletters/new',
  siteSettingsStyles: '/settings/styles',
  siteSettingsCustomDomain: '/settings/domain',
} as const
